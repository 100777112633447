import React from 'react';
import './App.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box } from '@mui/system';


export const PlotGraph = ({data, title, description}) => {
    const chart_ = React.useRef(null);

    React.useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create("chartdiv", am4charts.XYChart);

        chart.xAxes.push(new am4charts.DateAxis());
        chart.yAxes.push(new am4charts.ValueAxis());

        var i = 0
        for(var k in data) {
            if(k !== "meta"){
                createSeries("value" + i, k, data[k]);
                i++
            }
        }

        function createSeries(s, name, d) {
            var series = chart.series.push(new am4charts.LineSeries());
            var bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.radius = 2;
            series.dataFields.valueY = "value" + s;
            series.dataFields.dateX = "date";

            series.name = name;

            var segment = series.segments.template;
            segment.interactionsEnabled = true;

            var hoverState = segment.states.create("hover");
            hoverState.properties.strokeWidth = 3;

            var dimmed = segment.states.create("dimmed");
            dimmed.properties.stroke = am4core.color("#dadada");

            segment.events.on("over", function(event) {
                processOver(event.target.parent.parent.parent);
            });

            segment.events.on("out", function(event) {
                processOut(event.target.parent.parent.parent);
            });

            var data = [];

            for(var k in d) {
                var dataItem = { date: new Date(k) };
                bullet.tooltipText = "Value: [bold]{dateX}: {valueY}[/]";
                dataItem["value" + s] = d[k] * 100;
                data.push(dataItem);
            }

            series.data = data;
            return series;
        }

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.scrollable = true;

        var title_ = chart.titles.create();
        title_.text = title;
        title_.fontSize = 25;
        title_.paddingBottom = 25
        title_.tooltipText = description;


        chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
        chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

        chart.legend.itemContainers.template.events.on("over", function(event) {
            processOver(event.target.dataItem.dataContext);
        })

        chart.legend.itemContainers.template.events.on("out", function(event) {
            processOut(event.target.dataItem.dataContext);
        })

        function processOver(hoveredSeries) {
            hoveredSeries.toFront();

            hoveredSeries.segments.each(function(segment) {
                segment.setState("hover");
            })
        
        hoveredSeries.legendDataItem.marker.setState("default");
        hoveredSeries.legendDataItem.label.setState("default");

            chart.series.each(function(series) {
                if (series !== hoveredSeries) {
                    series.segments.each(function(segment) {
                        segment.setState("dimmed");
                    })
                    series.bulletsContainer.setState("dimmed");
            series.legendDataItem.marker.setState("dimmed");
            series.legendDataItem.label.setState("dimmed");
                }
            });
        }

        function processOut() {
            chart.series.each(function(series) {
                series.segments.each(function(segment) {
                    segment.setState("default");
                })
                series.bulletsContainer.setState("default");
            series.legendDataItem.marker.setState("default");
            series.legendDataItem.label.setState("default");
            });
        }
    
        chart_.current = chart;
    
        return () => {
            chart.dispose();
        };
      }, [chart_, data, title, description]);

      return (
        <>
        <Box id="chartdiv" style={{ marginLeft: "3em", width: "95%", height: "500px" }}></Box>
        <Box style={{marginTop: "2em", fontSize: "10pt"}} >
            *the graph legend is interactive. Click on the legend labels!
        </Box>
        </>
      );
    
}