import * as React from 'react';
import './App.css';
import ResponsiveAppBar from "./AppBar";
import { TrafficChange } from './TrafficChange';
import { PopularDomains } from './PopularDomains';
import { AttackLayer3Traffic } from './AttackLayer3Traffic';
import { Box } from '@mui/system';

const App = () => {
  
  const [task, setTask] = React.useState("TrafficChange")

  const handleTrafficChange = React.useCallback(() => {
    setTask("TrafficChange");
  }, []);

  const handlePopularDomains = React.useCallback(() => {
    setTask("PopularDonains");
  }, []);

  const handleAttackLayer = React.useCallback(() => {
    setTask("AttackLayer");
  }, []);

  const pages = React.useMemo(() => [
    { text: 'Attack Layer3 traffic', handler: handleAttackLayer},
    { text: 'Popular Domains', handler: handlePopularDomains},
    { text: 'Traffic Change', handler: handleTrafficChange }
  ], [handleAttackLayer, handlePopularDomains, handleTrafficChange]);

  const getComponentToRender = () => {
    return  task === "TrafficChange" ? <TrafficChange /> :
            task === "PopularDonains" ? <PopularDomains /> :
            task === "AttackLayer" ? <AttackLayer3Traffic /> : <Box></Box>
  }

  return (
    <Box className="App">
      <ResponsiveAppBar pages={pages} />
      {task ? getComponentToRender() : <Info />}
    </Box>
  );
}

const Info = () => {
  return (
    <Box>
      Traffic Change: Comparison between the total traffic change received from the endpoint over the http traffic change
      PopularDomains: List of popular domains, ranked by the domain rank received from the endpoint and also indicating what domains have changed compared to data from the previous 30 day mark.
      Attack Layer 3 Traffic: Layer 3 DDoS Attack over the last 30 Days
    </Box>
  )
}

export default App;
