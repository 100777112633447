import * as React from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { PlotGraph } from './PlotGraph';

export const AttackLayer3Traffic = React.memo(() => {

    const [data, setData] = React.useState("")
    const isMounted = React.useRef(false)

    React.useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    React.useEffect(() => {
        let cancel = false;

        const fetch = async () => {
            if (!cancel) {
                const res = await axios.get("https://router.shahidikram-cloudflare-assessment.workers.dev/attack-layer3")
                if (isMounted) {
                    setData(res["data"]);
                }
            }
        };
        fetch();

        return () => {
            cancel = true;
        }
    }, []);

    if (!data) {
        return <></>
    } else {
        return (
            <Box style={{ paddingTop: "8em" }}>
                <PlotGraph
                    data={data}
                    title={"Attack Layer 3 Traffic"}
                    description={"Layer 3 DDoS Attack over the last 30 Days"}
                />
            </Box>
        )
    }

});