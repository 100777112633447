import * as React from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { PlotGraph } from './PlotGraph';

export const TrafficChange = React.memo(() => {
    const [data, setData] = React.useState("")
    const isMounted = React.useRef(false)

    React.useEffect(() => {
      isMounted.current = true
  
      return () => {
        isMounted.current = false
      }
    }, [])

    React.useEffect(() => {
        let cancel = false;

        const fetch = async () => {
            if(!cancel) {
                const res = await axios.get("https://router.shahidikram-cloudflare-assessment.workers.dev/traffic-change")
                if(isMounted) {
                    setData(res["data"]["data"]);
                }
            }
        };
        fetch();

        return () => {
            cancel = true;
        }
    }, []);

    if(!data) {
        return <></>
    } else {
        return (
            <Box style={{ paddingTop: "8em" }}>
                <PlotGraph 
                    data={data} 
                    title={"Traffic Change"} 
                    description = {"Comparison between the total traffic change received from the endpoint over the http traffic change"}
                />
            </Box>       
        )
    }
    
});