import * as React from 'react';
import { Box } from '@mui/system';
import axios from 'axios';
import { DataTable } from 'mantine-datatable';
import { Fade, Tooltip, Typography } from '@mui/material';


export const PopularDomains = React.memo(() => {
    const [data, setData] = React.useState("")
    const isMounted = React.useRef(false)

    React.useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    React.useEffect(() => {
        let cancel = false;

        const fetch = async () => {
            if (!cancel) {
                const res = await axios.get("https://router.shahidikram-cloudflare-assessment.workers.dev/popular-domains", { withCredentials: false })

                if (isMounted) {
                    setData(res);
                }
            }
        };
        fetch();

        return () => {
            cancel = true;
        }
    }, []);

    if (!data) {
        return <></>
    } else {
        data.data.rankingEntries.sort((a, b) => (parseInt(a.rank) > parseInt(b.rank)) ? 1 : -1)
        const d = data.data.rankingEntries.map(
            (info) => {
                return (
                    {
                        rank: info.rank,
                        domain: info.domain,
                        Category: info.Category,
                        RankChange: (info.RankChange < 0 ? "⬇️" : info.RankChange > 0 ? "⬆️" : "-")
                    }
                )
            }
        )
        return (
            <Box style={{ paddingTop: "4em", marginLeft: "2em", marginRight: "2em" }}>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="List of popular domains, ranked by the domain rank;
                    Also indicates what domains have changed compared to data from the previous 30 day mark."
                    arrow
                    placement="top"
                >
                    <Box style={{ marginBottom: "2em" }}>
                        <Typography variant="h4" component="h4">
                            Popular Domains
                        </Typography>
                    </Box>
                </Tooltip>
                <DataTable
                    withBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    records={d}
                    columns={[
                        {
                            accessor: 'rank',
                            title: 'Rank',
                            textAlignment: 'center',
                        },
                        {
                            accessor: 'domain',
                            textAlignment: 'center',
                            title: 'Domain'
                        },
                        {
                            title: 'Category',
                            accessor: 'Category',
                            textAlignment: 'center',
                        },
                        {
                            title: 'Rank Change',
                            accessor: "RankChange",
                            textAlignment: "center"

                        }
                    ]}
                />
            </Box>
        );
    }

});